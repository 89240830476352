interface Social {
  slug: string;
  url: string;
  label: string;
}

export const useSocials = () => {
  const socials: Social[] = [
    {
      slug: 'facebook',
      url: 'https://www.facebook.com/zeronet.nz/',
      label: 'Facebook',
    },
    {
      slug: 'instagram',
      url: 'https://www.instagram.com/zeronetnz/',
      label: 'Instagram',
    },
  ];

  return {
    socials,
  };
};
